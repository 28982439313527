<template>
  <drawer-layout
    :is-preview="false"
    :isMaskClosable="isMaskClosable"
    :hide-edit-btn="hideEditBtn"
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    :permission="permission"
    :btnLoading="btnLoading"
    :confirm-btn-disable="confirmBtnDisable"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
    @onConfirm="onConfirm"
  >
    <div class="scroll-view" :class="{ 'allow-scroll': isPreview, 'no-scroll': isFrameHover }" slot="form">
      <a-table
        :rowSelection="rowSelection"
        @change="handleTableChange"
        :columns="columns"
        :dataSource="
          dataSource.filter(item => {
            return isView(item)
          })
        "
      ></a-table>
    </div>
  </drawer-layout>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { configFormData } from './config'
import CustormEditor from '@/components/jeecg/JEditor'
import { getAction, postAction } from '@/api/manage'
import pdf from 'vue-pdf'
import TagDragSelect from '@/components/TagDragSelect'
import { decodeWord, encodeWord } from '@/utils/util.js'

const defaultSelectedDeparts = []
const defaultSelectedPositions = []
const NOT_DICTIORARY_ITEM = {
  classId: '10',
  className: '',
  inputValue: '',
  inputVisible: false,
  words: []
}
const defaultFormData = {
  textLayout: 1,
  styleItem: {
    textAlign: 'left',
    lineHeight: '1.2',
    scaleY: 1,
    scaleX: 1,
    fontSize: 8,
    fontColor: '#000000',
    antiAliasMethod: 'antiAliasSharp'
    // outlineColor: '#ffffff',
    // outlineWidth: 4,
  },
  typeId: 1,
  classId: 1,
  isSync: false
}
export default {
  mixins: [DrawerMixins],
  components: {
    CustormEditor,
    pdf,
    TagDragSelect
  },
  props: {
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    propsUrl: {
      type: Object,
      default: {}
    },
    productionId: {
      type: [String, Number],
      default: ''
    },
    platformId: {
      type: [String, Number],
      default: ''
    },
    departmentId: {
      type: String,
      default: ''
    },
    positionId: {
      type: String,
      default: ''
    },
    permission: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'curRow',
    event: 'changeCurRow'
  },
  data() {
    return {
      dataSource: [],
      selectedRowKeys: [],
      selectionRows: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 60
        },
        {
          title: '名称',
          dataIndex: 'guideName',
          width: 170
        },
        {
          title: '分类',
          dataIndex: 'typeId',
          scopedSlots: { customRender: 'typeId' },
          width: 180
        },
        {
          title: '属性',
          dataIndex: 'classId',
          scopedSlots: { customRender: 'classId' },
          width: 120
        }
      ],
      inspectSimpleList: [
        { value: '0', label: '无' },
        { value: 'inspectSimpleBlack', label: '质检大师简黑名单生效' },
        { value: 'inspectSimpleFilter', label: '质检大师简白名单生效' },
        { value: 'inspectComplexBlack', label: '质检大师繁屏蔽词生效' },
        { value: 'inspectComplexFilter', label: '质检大师繁过滤词生效' },
        { value: 'inspectComplexNotDict', label: '质检大师繁非字库生效' },
        { value: 'inspectJapaneseBlack', label: '质检大师日黑名单生效' },
        { value: 'inspectJapaneseFilter', label: '质检大师日白名单生效' }
      ],
      list: [
        'inspectJapaneseBlack',
        'inspectJapaneseFilter',
        'inspectSimpleBlack',
        'inspectSimpleFilter',
        'inspectComplexBlack',
        'inspectComplexFilter',
        'inspectComplexNotDict'
      ],
      inspectSimpleValue: '0',
      isFrameHover: false,
      confirmBtnDisable: true,
      isAddBlackWord: true,
      selectedDeparts: [],
      selectedPositions: [],
      rules: {
        guideName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
        // displayName: [{ required: true, message: '请输入字体中文名称', trigger: 'blur' }]
      },
      uploadLoading: false,
      pdfPagesCount: 0,
      pdfDocument: null,
      configFormData,
      formData: {
        ...defaultFormData,
        guideData: '',
        blacklist: [],
        glossary: [], //专有词汇
        fonts: [Object.assign({}, configFormData.defaultFields)],
        size: Object.assign({}, configFormData.defaultSize),
        coverImage: Object.assign({}, configFormData.defaultCoverImage)
      },
      url: {
        ...this.propsUrl,
        fontsList: '/fonts/list'
      },

      labelWidth: '160',
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 16
        }
      },
      guideTemplates: [],
      headers: {},
      isBlack: false,
      ipagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      }
    }
  },
  methods: {
    isView(item) {
      let dis = false
      for (let p of this.userInfo.positions) {
        if (
          item.positions?.some(i => {
            return i.positionId == p.id && (i.type == 'VIEW' || i.type == 'EDIT')
          })
        ) {
          dis = true
          break
        }
      }
      if (this.isAdmin) dis = true
      return dis
    },
    isSucDis(item) {
      let dis = true
      for (let p of this.userInfo.positions) {
        if (
          item.positions?.some(i => {
            return i.positionId == p.id && i.type == 'EDIT'
          })
        ) {
          dis = false
          break
        }
      }
      if (this.isAdmin) dis = false
      return dis
    },
    save() {
      if (!this.selectionRows.length) {
        this.$message.error('请先勾选数据')
      }
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading = true
      console.log(this.selectionRows)
      let data = {
        productionId: this.productionId,
        ids: this.selectionRows.map(item => item.id).join(',')
      }
      postAction(`guide/addProductDefaultConfig?productionId=${this.productionId}&ids=${data.ids}`).then(async res => {
        if (res.success) {
          self.$message.success('添加成功')
          self.isSaved = true
          self.btnLoading = false
          self.closed()
        } else {
          self.btnLoading = false
          self.$message.error(res.message || res.msg)
        }
      })
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.savePageSize2LS(this.url.list, pagination.pageSize)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    inspectSimpleFilters(curRow) {
      let text = '无'
      this.inspectSimpleList.forEach(item => {
        if (curRow[item.value]) {
          text = item.label
        }
      })
      return text
    },
    inspectSimpleChange(e) {
      this.list.forEach(key => {
        if (key == e) {
          this.formData[key] = true
        } else {
          this.formData[key] = false
        }
      })
    },
    wordsChange(dataList, idx) {
      /* if(this.formData.classId==3){
        this.formData.blacklist[idx].words = dataList
        console.log({ 'this.formData.blacklist[idx].words': this.formData.blacklist[idx].words })
      }else{
        this.formData.glossary[idx].words = dataList
        console.log({ 'this.formData.glossary[idx].words': this.formData.glossary[idx].words })
      } */
      this.formData.blacklist[idx].words = dataList
      console.log({ 'this.formData.blacklist[idx].words': this.formData.blacklist[idx].words })
    },
    moveInIframe(e) {
      /* if (this.isFrameHover) return
      console.log('进入', e)
      this.isFrameHover = true */
      e.stopPropagation()
    },
    moveOutIframe(e) {
      if (!this.isFrameHover) return
      console.log('出去', e)
      this.isFrameHover = false
      e.stopPropagation()
    },
    onScroll(e) {
      if (this.isFrameHover) {
        console.log('停止父组件滚动')
        e.stopPropagation()
        e.preventDefault()
        return false
      }
      // Y轴滚动距离 偏移高度 总高度
      let { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log('onScroll...', { e, scrollTop, clientHeight, scrollHeight })
      /* console.log(`scrollTop + clientHeight ${scrollTop + clientHeight} >= scrollHeight-1 ${scrollHeight - 1}`) */
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log('滚到底部啦！！！')
        this.confirmBtnDisable = false
      }
    },
    initForm() {
      this.formData = {
        ...defaultFormData,
        ...this.propsFilters,
        blacklist: [],
        fonts: [Object.assign({}, configFormData.defaultFields)],
        size: Object.assign({}, configFormData.defaultSize),
        coverImage: Object.assign({}, configFormData.defaultCoverImage)
      }

      this.selectedDeparts = [...defaultSelectedDeparts]
      this.selectedPositions = [...defaultSelectedPositions]
      this.selectedRowKeys = []
    },

    async show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.inspectSimpleValue = '0'
      this.confirmBtnDisable = true
      this.getPosition()
      this.getDepartList()
      this.getDictItems()
      this.getTextStyleType()
      if (this.productionId) {
        this.formData.productionId = this.productionId
      } else if (this.platformId) {
        this.formData.platformId = this.platformId
      }
      this.getGuideTemplates()

      if (this.isEdit) {
        this.formData = this.curRow
        // console.log(this.formData, this.curRow,"this.formData = this.curRow")
        const { departments } = this.formData
        const { positions } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
        console.log({ positions: positions })
        this.selectedPositions = positions.map(item => item.positionId)
        console.log({ 'show this.selectedPositions': this.selectedPositions })
      }
      if (this.curRow.classId == 8 && this.curRow.guideData && this.curRow.guideData.startsWith('http')) {
        this.loadPdfPages(this.curRow.guideData)
      }
      this.formData = { ...this.formData, ...this.propsFilters }
      const res = await getAction(`/guide/productDefaultConfig?platformId=${this.platformId}&productionId=${this.productionId}`)
      this.dataSource = res.data||[]
      console.log({ 'this.formData': this.formData })
      console.log({ 'this.formData': this.formData })
      setTimeout(() => this.$nextTick(this.calcConfirmBtnDisable), 500)
      // this.calcConfirmBtnDisable()
    },

    calcConfirmBtnDisable() {
      let allowScroll = document.querySelector('.allow-scroll')
      console.log({ allowScroll })
      if (!allowScroll) {
        return
      }
      console.log(
        `allowScroll.scrollHeight ${allowScroll.scrollHeight} <= allowScroll.clientHeight ${allowScroll.clientHeight}`
      )
      if (allowScroll.scrollHeight <= allowScroll.clientHeight) {
        console.log('高度低于窗口可视区域高度')
        this.confirmBtnDisable = false
      }
    },
    onConfirm() {
      console.log('guide-modal onConfirm')
      this.$emit('onConfirm', this.curRow.id)
    },

    getGuideTemplates() {
      // TODO 这块等下再看
      if (this.guideTemplates.length == 0 && this.platformId && this.departmentId) {
        getAction('/guide/get_guide_by_depart', { id: this.departmentId }).then(res => {
          if (res.success) {
            this.guideTemplates = Object.assign([], res.data)
          }
        })
      } else if (this.guideTemplates.length == 0 && this.productionId && this.platformId) {
        getAction('/platform/get_guide_by_platform', { id: this.platformId }).then(res => {
          if (res.success) {
            this.guideTemplates = Object.assign([], res.data)
          }
        })
      }
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
      console.log(this.selectedRowKeys, this.selectionRows)
    },
    guideNameChanged(value) {
      if (!this.formData.id) {
        let matched = this.guideTemplates.filter(one => one.guideName == value)
        if (matched && matched.length > 0) {
          let one = matched[0]
          this.formData.sequenceNo = one.sequenceNo
          this.formData.description = one.description
          this.formData.classId = one.classId
          if (this.platformId && this.departmentId) {
            this.formData.departGuideId = one.guideId
          } else if (this.productionId && this.platformId) {
            this.formData.platformGuideId = one.id
          }
          switch (this.formData.classId) {
            case 1: //文本样式
              this.formData.textLayout = one.textLayout
              this.formData.styleItem = Object.assign({}, one.styleItem)
              break
            case 2: //富文本
            case 8: //PDF
              this.formData.guideData = one.guideData
              break
            case 3: //屏蔽词
              this.formData.blacklist = Object.assign([], one.blacklist)
              break
            case 4: //字体
              this.formData.fonts = Object.assign([], one.fonts)
              break
            case 5: //尺寸
              this.formData.size = Object.assign({}, one.size)
              break
            case 6: //封面
              this.formData.coverImage = Object.assign({}, one.coverImage)
              break
            case 7: //专有词汇
              this.formData.glossary = Object.assign([], one.glossary)
              break
          }
          this.$forceUpdate()
        }
      }
    },

    classIdChanged(value) {
      console.log(value)
      if (value == 1) {
        if (!this.curRow.styleItem) {
          this.curRow.textLayout = defaultFormData.textLayout
          this.curRow.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
        if (!this.formData.styleItem) {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
      }
      if (value == 10 && !this.formData.blacklist?.length) {
        this.formData.blacklist = [NOT_DICTIORARY_ITEM]
      }
      if (value == 12 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
        this.formData.blacklist.push({
          classId: value,
          className: '混淆词',
          words: [],
          inputVisible: false,
          inputValue: ''
        })
        this.formData.typeBackWord = 31
      }
      if (value == 11 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
        this.formData.blacklist.push({
          classId: value,
          className: '敏感词',
          words: [],
          inputVisible: false,
          inputValue: ''
        })
        this.formData.typeBackWord = 32
      }
      if (value == 7 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
      }
      if(value== 5){
        this.formData.size={}
      }
    },

    typeIdChanged(value) {
      if (value == 1) {
        if (!this.curRow.styleItem) {
          this.curRow.textLayout = defaultFormData.textLayout
          this.curRow.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
        if (!this.formData.styleItem) {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
      }
    },

    startEdit() {
      console.log('startEdit', { 'this.isEdit': this.isEdit })
      if (this.isEdit) {
        this.formData = this.curRow
        this.list.forEach(key => {
          if (this.formData[key]) {
            this.inspectSimpleValue = key
          }
        })
        const { departments } = this.formData
        if (departments) {
          this.selectedDeparts = departments.map(item => item.departId)
        }
        const { positions } = this.formData
        console.log({ positions: positions })
        if (positions) {
          this.selectedPositions = positions.map(item => item.positionId)
          console.log({ 'show this.selectedPositions': this.selectedPositions })
        }
      }
      if ((this.curRow.classId == 10 || this.formData.classId == 10) && !this.formData.blacklist?.length) {
        this.$set(this.formData, 'blacklist', [NOT_DICTIORARY_ITEM])
        this.$emit('changeCurRow', { ...this.curRow, blacklist: [NOT_DICTIORARY_ITEM] })
        console.log({ 'this.curRow': this.curRow, 'this.formData': this.formData })
      }
    },

    setFormData() {
      if (this.formData.classId == 3 || this.formData.classId == 10 || this.formData.classId == 7) {
        this.isBlack = true
      } else {
        this.isBlack = false
      }
    },
    handleOutLineColor(e) {
      if (e.target.value === '#000000') {
        this.formData.styleItem.outlineWidth = 3
      } else if (e.target.value === '#ffffff') {
        this.formData.styleItem.outlineWidth = 4
      }
    },
    handleAddGlossaryType(val) {
      // 判断是否在新增列表里边
      let hasAddNew = false
      /* if (!this.formData.glossary) {
        this.formData.glossary = []
      }
      this.formData.glossary.forEach(item => {
        if (item.classId == val) {
          hasAddNew = true
        }
      }) */
      if (!this.formData.blacklist) {
        this.formData.glossary = []
      }
      this.formData.blacklist.forEach(item => {
        if (item.classId == val) {
          hasAddNew = true
        }
      })
      if (hasAddNew) {
        this.$message.error('当前类型已经新增过了')
        return
      }

      let curItem = this.typeGlossaryList.filter(item => item.value === val)[0] || {}

      /* this.formData.glossary.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      }) */
      this.formData.blacklist.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      })
    },
    handleDeleteGlossaryType(curItem) {
      /* this.formData.glossary = this.formData.glossary.filter(item => item.classId !== curItem.classId) */
      this.formData.blacklist = this.formData.blacklist.filter(item => item.classId !== curItem.classId)
    },
    addGlossary(classId) {
      let one = this.formData.glossary.filter(item => item.classId == classId)
      if (one && one.length == 1) {
        one[0].words.push({ originalWords: '', translateWords: '' })
      }
    },
    removeGlossary(classId, index) {
      let one = this.formData.glossary.filter(item => item.classId == classId)
      if (one && one.length == 1) {
        one[0].words.splice(index, 1)
      }
    },
    handleAddBlackWord(val) {
      // 判断是否在新增列表里边
      let hasAddNew = false
      this.formData.blacklist.forEach(item => {
        if (item.classId === val) {
          hasAddNew = true
        }
      })

      if (hasAddNew) {
        this.$message.error('当前类型已经新增过了')
        return
      }

      let curItem = this.typeBackWordList.filter(item => item.value === val)[0] || {}

      this.formData.blacklist.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      })
    },
    handleDeleteBlackWordType(curItem) {
      this.formData.blacklist = this.formData.blacklist.filter(item => item.classId !== curItem.classId)
    },
    handleClose(removedTag) {
      this.formData.blacklist = this.formData.blacklist.map(each => {
        if (each.words.indexOf(removedTag) >= 0) {
          return {
            ...each,
            words: each.words.filter(tag => tag !== removedTag)
          }
        } else {
          return each
        }
      })
    },

    showInput(item) {
      this.formData.blacklist = this.formData.blacklist.map(each => {
        if (item.classId === each.classId) {
          return {
            ...each,
            inputVisible: true
          }
        } else {
          return each
        }
      })

      this.$nextTick(function() {
        let refName = `input_${item.classId}`
        this.$refs[refName][0].focus()
      })
    },

    handleInputChange(e) {
      this.inputValue = e.target.value
    },

    handleInputConfirm(curItem) {
      const inputValue = this.inputValue
      this.formData.blacklist = this.formData.blacklist.map(item => {
        if (item.classId === curItem.classId) {
          let words = item.words
          if (inputValue && words.indexOf(inputValue) === -1) {
            words = [...words, inputValue]
          } else {
            this.$message.error('当前屏蔽词已经新增过了')
          }

          return {
            ...item,
            words,
            inputVisible: false,
            inputValue: ''
          }
        } else {
          return {
            ...item
          }
        }
      })
    },
    handleSetOutlineColor(color) {
      this.formData.styleItem.outlineColor = color
    },
    handleSetOutlineWidth(width) {
      this.formData.styleItem.outlineWidth = width
    },
    addField() {
      this.formData.fonts.push(
        Object.assign({}, configFormData.defaultFields, {
          fontFamily: [this.configFormData.defaultFontFamily[0].displayName]
        })
      )
    },
    removeField(index) {
      if (this.formData.fonts.length <= 1) return
      this.formData.fonts.splice(index, 1)
    },
    classNameString(classId) {
      for (let i = 0; i < configFormData.classIds.length; i++) {
        if (classId == configFormData.classIds[i].value) {
          return configFormData.classIds[i].label
        }
      }
    },
    typeNameString(typeId) {
      for (let i = 0; i < configFormData.typeIds.length; i++) {
        if (typeId == configFormData.typeIds[i].value) {
          return configFormData.typeIds[i].label
        }
      }
    },

    getDisplayFontSize(styleItem) {
      for (let idx = 0; idx < configFormData.fontSize.length; idx++) {
        if (configFormData.fontSize[idx].value == styleItem.fontSize) {
          return configFormData.fontSize[idx].label
        }
      }
      styleItem.fontSize = 33
      return 8
    },

    getAntiAliasMethod(styleItem) {
      for (let idx = 0; idx < configFormData.antiAliasMethod.length; idx++) {
        if (configFormData.antiAliasMethod[idx].value == styleItem.antiAliasMethod) {
          return configFormData.antiAliasMethod[idx].label
        }
      }
      return '锐利'
    },

    handleChangeUpload(info) {
      this.uploadLoading = true
      if (info.file.status !== 'uploading') {
        this.uploadLoading = false
      }
      if (info.file.status === 'done') {
        this.uploadLoading = false
        if (info.file.response.success) {
          this.loadPdfPages(info.file.response.message, () => {
            this.formData.guideData = info.file.response.message
          })
        }
      } else if (info.file.status === 'error') {
        this.uploadLoading = false
        this.$message.error(`${info.file.name} 上传失败`)
      }
    },

    loadPdfPages(url, callback) {
      var loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.pdfDocument = loadingTask
        this.pdfPagesCount = pdf.numPages
        if (callback) {
          callback()
        }
      })
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    calcWord(word) {
      // console.log('calcWord', { word });
      if (!word) return ''
      if (typeof word == 'string') {
        return decodeWord(word)
      } else if (typeof word == 'object') {
        const { value, target } = word
        if (target) {
          return decodeWord(value + ' → ' + target)
        } else {
          return decodeWord(String(value))
        }
      }
      // console.log({ word });
      // return word?.value ? (word.value + (word.target ? ' → ' + word.target : '')) : word
    }
  },
  watch: {
    formData() {
      if (this.propsFilters.typeId == 4 && this.formData.classId == 1) {
        this.formData.classId = 3
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        hideDefaultSelections: false,
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: this.isSucDis(record)
          }
        })
      }
    }
  },
  created() {
    const params = {
      pageNo: 1,
      pageSize: 100000
    }
    getAction(this.url.fontsList, params).then(res => {
      if (res.success) {
        this.configFormData.defaultFontFamily = []
        res.data.records.filter(one => {
          let found = false
          for (let idx = 0; idx < this.configFormData.defaultFontFamily.length; idx++) {
            if (this.configFormData.defaultFontFamily[idx].displayName == one.displayName) {
              found = true
              break
            }
          }
          if (!found) {
            this.configFormData.defaultFontFamily.push(one)
          }
        })
        // this.configFormData.defaultFontFamily = res.data.records

        // this.formData.fonts[0].fontFamily = [res.data.records[0].displayName]
        this.formData.fonts[0] = Object.assign(this.formData.fonts[0] || {}, {
          fontFamily: [this.configFormData.defaultFontFamily[0]?.displayName]
        })
      }
    })
    const token = Vue.ls.get(ACCESS_TOKEN)
    this.headers = { 'X-Access-Token': token }
  }
}
</script>
<style scoped lang="less">
/deep/.ant-tag {
  margin-bottom: 5px;
}

/deep/ .ant-drawer-body {
  padding: unset !important;
}

/deep/ .eidtor-btn {
  margin: 24px 48px 10px 0;
}
</style>
<style lang="less">
.scroll-view {
  height: 80vh;
  overflow-y: auto;
  padding: 24px 44px 0 48px;
}

// 千万不要改！
.allow-scroll {
  height: 80vh;
  overflow-y: auto;
  padding: 24px 44px 0 48px;
}

.no-scroll {
  overflow-y: hidden !important;
  padding-right: 48px !important;
}

// .no-scroll {
//   overflow-y: hidden;
// }

.fontColor,
.outlineColor {
  width: 32px;
  padding: 0;
  margin: 0;
}

.inputNumber {
  width: 70px;
}

.black_word {
  margin-bottom: 15px;
}

.black_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-child {
    font-size: 20px;
    cursor: pointer;
  }
}

.default_outline_color,
.default_outline_width {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;

  span {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border: 1px solid #44afe4;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .active_outlineColor,
  .active_outlineWidth {
    -webkit-box-shadow: 0 0 5px rgba(0, 113, 241, 1);
  }
}

.form_min_width > div {
  display: inline-block;
  width: 100px;
}
</style>
